// Conceito

.EsteticaConceito{
    height: 600px;
    .boxEsteticaConceito{
        min-height: 600px;
        display: table;
        position: relative;
        .textConceito{
            display: table-cell;
            vertical-align: middle;
            min-height: 600px;
            position: relative;
            h2{
                color: #272727;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1.8px;
                text-transform: uppercase;
                margin-bottom: 20px;
                width: 90%;
            }
            p{
                color: #8F8A8A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 142.3%; /* 18.499px */
                letter-spacing: 0.78px;
                margin-bottom: 40px;
            }
        }
    }
    img{
        width: 90%;
        float: left;
        margin-top: 160px;
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .EsteticaConceito {
        height: auto;
        img {
            width: 90%;
            float: inline-end;
            margin-top: 160px;
            margin: auto;
            margin-top: 60px;
        }
        .boxEsteticaConceito {
            padding: 60px 40px;
            .textConceito {
                h2 {
                    font-size: 20px;
                    margin-bottom: 20px;
                    width: 100%;
                }
            }
        }
    }
}

