// Numeros

.detalhesVeiculo{
    padding: 80px 0 40px;
    h4{
        color: #A1A1A1;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    h3{
        color: #272727;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: 0;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    p{
        color: #8F8A8A;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.9px;
        margin-bottom: 20px;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        li{
            list-style: none;
            padding: 0;
            margin: 0;
            border-bottom: 1px solid #DEDEDE;
            color: #272727;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.72px;
            text-transform: uppercase;
            padding-bottom: 12px;
            strong{
                color: var(--tertiaryColor);
                font-size: 14px;
                position: relative;
                top: -2px;
                margin-left: 6px;
            }
            span{
                color: #A7A7A7;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.6px;
                text-transform: uppercase;  
                display: inline-block;
                width: 100%;
            }
            &:last-child{
                border: none;
            }
        }
    }
    .valor{
        width: 100%;
        display: flex;
        justify-content: space-between;   
        background: #DEDEDE;
        padding: 20px 40px;
        margin-bottom: 40px;
        margin-top: 20px;
        span{
            text-transform: uppercase;
            font-weight: bold;
            line-height: 32px;
            color: #A7A7A7;
            font-style: normal;
            font-weight: 800;
            letter-spacing: 0.78px;
            text-transform: uppercase;
        }
        h3{
            color: #272727;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 1.02px;
            text-transform: uppercase;
            line-height: 32px;
            margin-bottom: 0;
        }
    }
    .acoes{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .btn{
            &.btn-primary{
                background: #35363A;
                line-height: 18px;
                width: 100%;
                margin-right: 16px;
                text-transform: uppercase;
                font-size: 16px !important;
                padding: 20px 0 !important;
            }
            &.btn-icon{
                border: 1px solid #A7A7A7 !important;
                color: #A7A7A7;
                background: none;
                line-height: 18px;
                width: 80px;
                font-size: 30px !important;
                
            }
            &:hover{
                background: var(--tertiaryColor);
                color: var(--whiteColor);
                border-color: var(--tertiaryColor) !important;
              }

        }
    }
    .imgDetalhe{
        width: 100%;
        height: 590px;
        float: right;
        margin-top: 40px;
        position: relative;
        border-radius: 5px;
        background-color:  #FFFFFF;
        box-shadow: 0px 4.48px 28.224px 0px #AEAEAE;
        &::before{
            content: "";
            width: 100%;
            height: 60%;
            background: #DEDEDE;
            position: absolute;
            top: calc(50% - 30px);
            left: -40px;
            z-index: -1;
        }
        &::after{
            content: "";
            width: 100%;
            height: 60%;
            border: 1px solid #DEDEDE;
            position: absolute;
            top: -40px;
            right: -40px;
            z-index: -1;
        }
        span{
            border-radius: 5.542px;
            border: 1.385px solid #2CBC46;
            background: linear-gradient(90deg, rgba(48, 189, 68, 0.55) -17.83%, rgba(29, 29, 29, 0.90) 130.07%);
            color: var(--whiteColor);
            font-weight: 600;
            top: 30px;
            right: 30px;
            border-radius: 4px;
            position: absolute;
            padding: 6px 40px;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1.2px;
        }
        .playVideos{
            border-radius: 5px;
            border: 2px solid #9e9c9c;
            background: rgba(35, 35, 35, 0.65);
            width: 280px;
            height: 70px;
            display: inline-block;
            position: absolute;
            bottom: 30px;
            right: 30px;
            opacity: 0.9;
            &:hover{
                cursor: pointer;
                opacity: 1;
            }
            p{
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.72px;
                text-transform: uppercase;
                width: 60%;
                float: right;
                line-height: 64px;
                text-align: center;
            }
            .play{
                background: #FFF;
                width: 40%;
                float: left;
                height: 70px;
                border-radius: 5px 0 0 5px;
                position: relative;
                i{
                    font-size: 34px;
                    color: var(--whiteColor);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    z-index: 2;
                    margin-left: -20px;
                    margin-top: -32px;
                    opacity: 0.7;
                }
            }
            
        }
    }
    span.Vendido{
        border-radius: 6px;
        border: 0.845px #2CBC46;
        background: rgba(48, 189, 68, 0.83); 
        color: #257530;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.71px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 8px 20px;
        display: inline-block;
        margin: 14px 0;
        .icons{
            font-size: 20px;
            position: relative;
            top: -2px;
        }
    }
}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .detalhesVeiculo {
        padding: 0px 0 40px;
        .container{
            .row{
                > div{
                    &:nth-child(1){
                        order: 2;
                        padding: 30px;
                    }
                    &:nth-child(2){
                        padding: 0;
                    }
                }
            }
        }
        .imgDetalhe{
            box-shadow: none;
            border-radius: 0;
            margin-top: 0;
            height: 300px;
            &::after, &::before{
                display: none;
            }
        }
        h4 {
            font-size: 14px;
        }
        h3 {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 10px;
        }
        .valor {
            padding: 15px 20px;
            h3 {
                font-size: 18px;
            }
        }
        .btn.btn-down {
            margin: 30px auto;
        }
        p {
            color: #8F8A8A;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            margin-bottom: 20px;
        }
        .acoes {
            .btn.btn-primary {
                font-size: 14px !important;
            }
        }
        .playVideos {
            width: 150px !important;
            bottom: 20px !important;
            right: 20px !important;
            .play{
                width: 100% !important;
            }
            p{
                display: none;
            }
        }
    }
    ul {
        li{
            strong {
                display: block;
                margin-left: 0 !important;
                margin-top: 4px;
            }
         }
    }
}


