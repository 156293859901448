// Funilaria

.BannerFunilaria{
    height: 680px;
    background: #262626;
    position: relative;
    overflow: hidden;
    padding-bottom: 60px;
    padding-top: 50px;
    .row{
        position: relative;
        height: 600px;
    }
    .imgBannerFunilaria {
        height: 600px;
        position: relative;
        z-index: 2;
    }
}
.ChamadaFunilaria{
    padding: 80px 0;
    background: #D0D0D0;
    text-align: center;
    margin-top: -180px;
    z-index: 1;
    position: relative;
    p{
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.333%;
        text-transform: uppercase;
    }
    h4{
        color: #000;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
    }
}



@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .BannerFunilaria {
        height: 425px;
        .imgBannerFunilaria {
            height: 200px;
            position: relative;
            z-index: 2;
            background-size: contain !important;
            margin-top: 40px;
        }
    }
    .NumerosFunilaria{
        padding: 80px 0 40px 0;
        .container{
            .row{
                > div{
                    margin-bottom: 20px;
                }
            }
        }
    }
    .ChamadaFunilaria {
        padding: 80px 0 30px;
        h4 {
            font-size: 18px;
        }
        p {
            color: #000;
            font-size: 18px;
            width: 90%;
            text-transform: inherit;
            margin: auto;
        }
    }
}
