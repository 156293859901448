// Seguranca

.Seguranca{
    background: #DEDEDE;
    padding: 120px 0 120px;
    .imgSeguranca{
        
        background:  lightgray 50% / cover no-repeat;
        box-shadow: 0px 4.48px 28.224px 0px #AEAEAE;
        width: 100%;
        position: relative;
        img{
            width: 100%;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            z-index: 1;;
        }
        &::after{
            content: "";
            width: 100%;
            height: 100%;
            border: 1px solid #BDBDBD;
            position: absolute;
            top: -30px;
            right: -30px;
            z-index: 0;
        }
    }
    .textSeguranca{
        padding: 30px 10%;
        h3{
            color: #272727;
            font-size: 25px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin-bottom: 30px;
            strong{
                font-weight: 900;
            }
        }
        i{
            width: 59px;
            height: 59px;
            border-radius: 6px;
            background: var( --tertiaryColor) url(./img/iconSeguranca.webp) no-repeat center;
            text-align: center;
            display: inline-block;
            margin-bottom: 20px;
        }
        ul{
            margin: 0;
            padding: 0;
            li{
                color: #8F8A8A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.78px;
                list-style-image: url(./img/iconCheck.webp);
                margin-bottom: 10px;
                margin-left: 20px;
            }
        }
    }
}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .Seguranca {
        background: #DEDEDE;
        padding: 60px 20px 60px;
        .textSeguranca {
            margin-bottom: 40px;
            padding: 60px 10px 0px;
        }
        .imgSeguranca{
            &::after {
                top: -18px;
                right: -18px;
            }
            &::before {
                top: 18px;
                left: -18px;
            }
        }
        .container{
            .row{
                .boxSeguranca {
                    order: 2;
                }
            }
        }
    }
}



