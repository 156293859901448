// Galeria
.galeriaContainer{
    .btn.btn-tertiary{
        display: none;
    }
    .galeria{
        margin-bottom: 120px;
        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
        .row{
            padding: 0;
            margin: 0;
            > div{
                padding: 0;
                margin: 0;
            }
        }
        .photo{
            width: 100%;
            height: 300px;
            display: inline-block;
            position: relative;
            float: left;
            border: 4px solid rgba(238, 238, 238, 0.87);
            overflow: hidden;
            cursor: pointer;
            &:hover{
                opacity: 0.9;
            }
        }
        
    }
}


.galeria::-webkit-scrollbar {
    width: 10px; 
}


.galeria::-webkit-scrollbar-thumb {
    background-color: var(--tertiaryColor); 
    border-radius: 6px; 
}


.galeria::-webkit-scrollbar-thumb:hover {
    background-color: var(--tertiaryColor); 
}

@media (max-width: 768px) {
    .galeriaContainer{
        padding-bottom: 40px;
        .galeria {
            max-height: 400px;
            margin-bottom: 0;
            .photo {
                height: 134px;
            }
        }
        .btn {
            margin-bottom: 60px !important;
            width: 100%;
            margin-top: 10px;
        }
    }
}