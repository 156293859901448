// Não Encontrol

.naoEncontrol{
    margin-bottom: 40px;
    .boxNaoEncontrol{
        background: url(./img/bg-naoEncontrol.png) no-repeat center / cover;
        width: 100%;
        height: 230px;
        display: inline-block;
        margin: auto;
        border-radius: 8px;
        overflow: hidden;
        padding: 80px 0;
        h3{
            font-size: 18px;
            color: var(--tertiaryColor);
            text-transform: uppercase;
            font-weight: bold;
        }
        h2{
            font-size: 25px;
            color: var(--whiteColor);
            text-transform: uppercase;
            font-weight: bold;
        }
        .btn{
            float: right;
            width: 80%;
            margin-top: 20px;
        }
    }
}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .naoEncontrol {
        .boxNaoEncontrol {
            padding: 40px 30px;
            text-align: center;
            .btn {
                float: inherit;
            }
        }
    }
}