@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// standard colors
:root {
  --primaryColor: #212121;
  --secondaryColor: #828282;
  --tertiaryColor: #2CBE48;
  --whiteColor: #FFFFFF;
  --callToActionColor: #35363A;

  --primaryTextColor: #272727;
  --secondaryTextColor: #A7A7A7;

  --FontDefault: 'Red Hat Display', sans-serif;
  --FontSecondary: 'Poppins', sans-serif;
  --FontTertiary: 'Roboto', sans-serif;
}

body {
  font-family: var(--FontDefault) !important;
  background: #EEEEF2 !important;
}
.btn{
  border: 0 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 10px 40px  !important;
  text-transform: uppercase;
  font-weight: bold !important;
  letter-spacing: 0.7px;
  &.btn-primary{
    background: var(--primaryColor);
    &:hover{
      background: var(--tertiaryColor);
      color: var(--whiteColor);
      border-color: var(--tertiaryColor) !important;
    }
    &.disable{
      background: none;
      color: var(--primaryColor);
      border: 1px solid var(--primaryColor) !important;
      &:hover{
        background: var(--primaryColor);
        color: var(--whiteColor);
        border-color: var(--primaryColor) !important;
      }
    }
  }
  &.btn-secondary{
    background: var(--tertiaryColor);
    &:hover{
      background: var(--primaryColor);
      color: var(--whiteColor);
      border-color: var(--primaryColor) !important;
    }
  }
  &.btn-tertiary{
    background: none;
    color: var(--primaryColor);
    border: 1px solid var(--primaryColor) !important;
    &:hover{
      background: var(--tertiaryColor);
      color: var(--whiteColor);
      border-color: var(--tertiaryColor) !important;
    }
  }
  &.btn-quaternary{
    background: var(--secondaryTextColor);
    color: var(--whiteColor);
    border: 1px solid var(--secondaryTextColor) !important;
    &:hover{
      background: var(--tertiaryColor);
      color: var(--whiteColor);
      border-color: var(--tertiaryColor) !important;
    }
  }
  &.btn-border{
    background: none;
    color: var(--secondaryColor);
    border: 1px solid #D0D0D0 !important;
    &:hover{
      background: var(--primaryTextColor);
      color: var(--whiteColor);
    }
  }
  &.btn-plus{
    width: 63px;
    height: 58px;
    border: 1px solid #D0D0D0 !important;
    color: var(--secondaryTextColor);
    text-align: center;
    padding: 0 !important;
    line-height: 55px;
    font-size: 15px !important;
    &:hover{
      background: var(--tertiaryColor);
      color: var(--whiteColor);
      border-color: var(--tertiaryColor) !important;
    }
  }
  &.btn-plus-circle{
    border: 1px solid #CFCFCF !important;
    border-radius: 200px !important;
    color: #626262;
    background: rgba(255,255,255,0.5);
    width: 140px;
    height: 140px;
    position: absolute;
    text-align: center;
    font-size: 10px !important;
    top: 200px;
    right: 100px;
    padding: 40px 0 0 0 !important;
    i{
      display: inline-block;
      width: 100%;
      font-size: 18px;
    }
    &:hover{
      background: rgba(255,255,255,0.3);
      color: #454343;
      border: 1px solid #E2DFDF !important;
    }
  }
  &.btn-close{
    background: none;
    position: absolute;
    right: 30px;
    height: 40px;
    width: 30px;
    top: 30px;
    color: #8F8F8F;
    z-index: 9;
    padding: 0 !important;
    i{
      font-size: 24px;
      color: #727272;
    }

  }
  &.btn-icon {
    background: var(--primaryColor);
    color: var(--whiteColor);
    padding: 14px 20px !important;
    font-size: 20px !important;
    &:hover{
      background: var(--tertiaryColor);
      color: var(--whiteColor);
      border-color: var(--tertiaryColor) !important;
    }
  }
  &.btn-down{
    border: 1px solid #A7A7A7 !important;
    color: #A7A7A7;
    font-size: 20px !important;
    background: none;
    padding: 0 !important;
    font-size: 29px !important;
    border-radius: 100px !important;
    line-height: 57px;
    width: 64px;
    height: 64px;
    border-color: 1px solid var(--tertiaryColor) !important;
    margin: 60px 0 40px;
    &:hover{
      background: var(--primaryColor);
      color: var(--whiteColor);
      
    }
  }
  &.callToAction{
    padding: 20px 120px  !important;
    background: var(--callToActionColor);
    font-size: 14px !important;
    margin: 20px 0;
  }
}
.titilos{
    padding-top: 30px;
    padding-bottom: 40px;
    font-weight: 800;
    font-size: 28px;
    color: var(--secondaryTextColor);
    text-transform: uppercase;
  span{
    color: var(--primaryTextColor);
    font-size: 12px;
    display: inline-block;
    width: 100%;
  } 
}
.onMobile{
  display: none;
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1380px) {
  .btn {
    font-size: 10px !important;
    padding: 12px 30px !important;
  }
}
@media (max-width: 768px) {
  .titilos {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px;
    padding-bottom: 0;
    margin-bottom: 40px;
    text-align: center;
    span{
      text-align: center;
    }
  }
  .btn.btn-down {
    font-size: 20px !important;
    line-height: 42px;
    width: 48px;
    height: 48px;
  }
  .onMobile{
    display: inline-block;
  }
}