// Apaixonados

.Apaixonados{
    padding: 80px 0;
    background: url(./img/efeito1.webp) no-repeat top left, #2F2F2F url(./img/efeito2.webp) no-repeat bottom right;
    .timeApaixonados{
        border: 1px solid #FFF;
        border-radius: 7px;
        text-align: center;
        display: flex;
        align-items: stretch;
        div{
            padding: 20px;
            width: 40%;
            &:nth-child(2){
                width: 20%;
            }
            h2{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                letter-spacing: 1.08px;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            p{
                color: #FFF;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .Apaixonados{
        padding: 90px 20px 60px 20px;
        background: #262626;
        margin-top: -4px;
        .timeApaixonados {
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                left: 50%;
                top: -95px;
                margin-left: -50px;
                width: 100px;
                height: 100px;
                background: url(./img/setaBottom.webp) no-repeat center;
            }
            .desdeApaixonados{
                width: 100%;
                padding-bottom: 0;
            }
            .nextApaixonados{
                width: 100%;
                display: none;
            }
            .textApaixonados{
                width: 100%;
                margin-bottom: 10px;
            }
            div {
                padding: 10px 20px;
                p {
                    font-size: 13px;
                    text-align: center;
                }
            }
        }
    }
}


