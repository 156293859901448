.VideoModal  {
    .modal-header{
        background: #FFF !important;
        padding: 10px 0 !important;
    }
    .modal-body{
        padding: 0px !important;
    }
    .modal-dialog{
        max-width: 780px;
    }
    .logo{
        width: 90px;
    }
}
