// Contexto

.Contexto{
    padding: 80px 0;
    height: auto;
    .textContexto{
        position: relative;
        text-align: center;
        h3{
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--whiteColor);
            font-weight: 900;
            text-transform: uppercase;
            font-size: 38px;
            color: transparent;
            line-height: 40px;
        }
        h2{
            font-size: 50px;
            font-weight: 900;
            color: var(--whiteColor);
            text-transform: uppercase;
            line-height: 40px;
        }
        p{
            color: #272727;
            text-align: left;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1.08px;
        }
    }
}
