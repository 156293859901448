// SobreBanner

.Conheca{
    padding: 120px 0;
    background: url(./img/bgQuem.webp) no-repeat left top;
    .imgConheca{
        img{
            width: 80%;
            margin: auto;
            display: block;
        }
    }
    .textConheca{
        padding-left: 5%;
        h3{
            color: #ABABAB;
            font-size: 13px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.78px;
            text-transform: uppercase;
        }
        h2{
            color: #272727;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            width: 80%;
        }
        p{
            color: #8F8A8A;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.78px;
            margin-bottom: 40px;
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1880px) {
    .Conheca {
        background-position: left -383px top;
        .textConheca {
            h2{
                font-size: 24px;
            }
        }
    }
}
@media (max-width: 768px) {
    .Conheca{
        background: #2CBE48 url(./img/bg.webp) no-repeat center bottom;
        padding-top: 60px;
        padding-bottom: 0;
        .textConheca {
            text-align: center;
            padding: 0 20px 40px;
            h2 {
                font-size: 20px;
                width: 100%;
            }
            h3, p{
                color: var(--whiteColor);
            }
            p{
                margin-top: 20px;
            }
        }
        .imgConheca img {
            margin-top: -20px;
            margin-bottom: 20px;
        }
        .row{
            > div{
                &:nth-child(1){
                    order: 2;
                }
                &:nth-child(2){
                    order: 1;
                }
            }
        }
    }
}



