// PodeGostar

.PodeGostar{
    position: relative;
    overflow: hidden;
    padding: 20px 0 100px;
    &::before{
        content: "Pode Gostar";
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(217,217,217,0.60) 0%, rgba(217,217,217,0.3) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 68px;
        position: absolute;
        right: -200px;
        top: 280px;
        font-weight: 900;
        transform: rotate(270deg);
    }
    .sliderDestaques{
        padding:  0;
    }
    .SliderControl{
        position: relative;
        
        a.nextSlider {
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #FFF;
            position: absolute;
            right: 61px;
            bottom: -50px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }
        a.prevSlider {
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #FFF;
            position: absolute;
            right: 10px;
            bottom: -50px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
            
        }
    }
    .slick-arrow{
        display: none !important;
    }
    .slick-slider{
        .slick-slide{
            padding: 10px;
        }
    }
    
    .itemVeiculos{
        height: 360px;
        overflow: hidden;
        position: relative;
        .foto{
            width: 100%;
            height: 360px;
            display: inline-block;
            background: #000;
            transition: all ease-in 0.2s;
            position: absolute;
            top: 0;
            span{
                background: rgb(48,189,68);
                background: linear-gradient(90deg, rgba(48,189,68,1) 55%, rgba(29,29,29,1) 90%);
                border: 1px solid var(--tertiaryColor);
                color: var(--whiteColor);
                font-weight: 600;
                top: 30px;
                left: 30px;
                border-radius: 4px;
                position: absolute;
                padding: 2px 14px;
                text-transform: uppercase;
                font-size: 14px;
            }
            &::before{
                content: "";
                background: linear-gradient(180deg, rgba(4, 4, 4, 0.00) 0%, rgba(4, 4, 4, 0.37) 67.71%, rgba(4, 4, 4, 0.55) 100%);
                height: 50%;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        .desc{
            position: absolute;
            bottom: -123px;
            width: 100%;
            transition: all ease-in 0.2s;
            .modelo{
                background: none;
                padding: 10px 30px;
                height: 88px;
                h3{
                    color: var(--whiteColor);
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    span{
                        color: var(--tertiaryColor);
                        font-size: 12px;
                        text-transform: uppercase;
                        display: inline-block;
                        width: 100%;
                    }
                }
                .marca{
                    width: 44px;
                    height: 44px;
                }
            }
            .valor{
                padding: 25px 30px;
                border: 1px solid #D2D2D2;
                background: var(--whiteColor);
                height: 123px;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                .valorAno{
                    span{
                        font-weight: bold;
                        font-size: 12px;
                        color: var(--secondaryTextColor);
                    }
                    h5{
                        font-weight: bold;
                        color: var(--primaryTextColor);
                        margin-bottom: 4px;
                    }
                    h6{
                        font-weight: bold;
                        font-size: 13px;
                        color: var(--secondaryTextColor);
                    }
                }
                .btn-plus{
                    margin-top: 6px;
                }
            }
        }
        &:hover{
            cursor: pointer;
            .foto{
                transform: scale(1.2);
                top: -100px;
            }
            .desc{
                bottom: 0;
                .modelo{
                    background: #212121;
                }
            }
            
        }
    }
    .headerAction{
        text-align: right;
        padding-top: 60px;
    }
    &.blindados{
        background: rgb(36,36,36);
        background: linear-gradient(90deg, rgba(36,36,36,1) 0%, rgba(47,47,47,1) 100%);
        &::before{
            content: "blindados";
            top: 320px !important;
            background: linear-gradient(90deg, rgba(217,217,217,0.20) 0%, rgba(217,217,217,0.05) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .titilos{
            span{
               color: var(--whiteColor); 
            }
        }
        .headerAction{
            .btn-primary{
                background: var(--secondaryTextColor);
                &:hover{
                    background: var(--tertiaryColor);
                }
            }
        }
        .SliderControl{
            position: relative;
            
            a.nextSlider {
                border: 1px solid #D0D0D0;
                background: #35363A;
                &:hover{
                    background: #F5F5F5;
                    cursor: pointer;
                }
            }
            a.prevSlider {
                border: 1px solid #D0D0D0;
                background: #35363A;
                &:hover{
                    background: #F5F5F5;
                    cursor: pointer;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .PodeGostar{
        .sliderDestaques {
            padding: 20px 60px 0 10px;
            width: 85%;
        }
    }
}