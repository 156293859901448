.formFaleConosco{
    padding: 120px 0 120px 0;
    margin-top: 0px;
    background: linear-gradient(180deg, #EEE 0%, rgba(237, 237, 237, 0.00) 100%);
    position: relative;

    h2{
        color: var(--primaryColor);
        font-size: 32px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 100px;
        margin-bottom: 10px;
    }
    h4{
        color: #272727;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1.02px;
        margin-bottom: 60px;
    }
    .formFaleConoscoImg{
        width: 100%;
        height: 660px;
        border-radius: 4px;
        background: url(./img/ImgFaleConosco.webp) no-repeat center / cover;
    }

    .form{
        border-radius: 6px;
        border: 1px solid #E0E0E0;
        background: #FFF;
        overflow: hidden;
        position: relative;
        .formFaleConoscoForm{
            padding: 60px 80px;
            h3{
                color: #ABABAB;
                font-size: 13px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.78px;
                text-transform: uppercase;
                margin-bottom: 10px;
                margin-top: 10px;
            }
            h2{
                color: #35363A;
                font-size: 28px;
                font-style: normal;
                font-weight: 800;
                line-height: 112.3%; /* 31.444px */
                letter-spacing: 1.68px;
                text-transform: uppercase;
                margin-bottom: 40px;
                margin-top: 0;
            }
            .Label {
                width: 100%;
                input{
                    border-radius: 0;
                    padding-left: 0;
                    padding-right: 0;
                    border: none;
                    width: 100%;
                    border-bottom: 1px solid #dedede !important;
                    box-shadow: none !important;
                    background: none;
                    color: #9B9B9B;
                    display: inline-block;
                    margin-bottom: 20px;
                    height: 48px;
                    &::placeholder {
                        font-weight: 600;
                        font-size: 14px;
                        color: #9B9B9B !important;
                    }
                    
                }
                &.error{
                    input{
                        border-bottom: 1px solid red !important;;
                    }
                }
            }
            .btn-secondary{
                float: left;
                padding-left: 80px !important;
                padding-right: 80px !important;
            }
            .validacao{
                height: 40px;
            }
    
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .formFaleConosco {
        padding: 60px 10px 60px 10px;
        .formFaleConoscoImg {
            display: none;
            h3 {
                margin-bottom: 0;
            }
        }
        .form {
            .formFaleConoscoForm {
                padding: 40px 30px;
                h2 {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}
