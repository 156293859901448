// buscaBanner

.BannerCotacaoBlindagem{
    background: #262626 url(./img/bgBannerCotacaoBlindagem.webp) no-repeat center / cover;
    height: 100vh;
    min-height: 500px;
    padding-top: 50px;
    .boxBannerCotacaoBlindagem{
        height: 100vh;
        min-height: 500px;
        display: table;
        position: relative;
        .textBannerCotacaoBlindagem{
            display: table-cell;
            vertical-align: middle;
            height: 100vh;
            min-height: 500px;
            position: relative;
            h3{
                color: #2CBE48;
                font-size: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                letter-spacing: 2.4px;
                text-transform: uppercase;
            }
            h2{
                font-size: 50px;
                font-weight: 900;
                color: var(--whiteColor);
                text-transform: uppercase;
                line-height: 40px;
            }
            p{
                color: #F3F3F3;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 142.3%;
                letter-spacing: 0.96px; 
                width: 60%;
            }
            .btn{
                margin-top: 40px;
                margin-bottom: 0;
                &.btn-down{
                    margin-top: 60px;
                }
            }
        }
    }


}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .BannerCotacaoBlindagem{
        padding: 60px 20px 20px;
        background: #262626 url(./img/bgBannerCotacaoBlindagemMobile.webp) no-repeat center / cover;
        height: 100vh;
        .boxBannerCotacaoBlindagem {
            .textBannerCotacaoBlindagem {
                h3 {
                    color: #2CBE48;
                    font-size: 20px;
                    letter-spacing: 1px;
                }
                h2 {
                    font-size: 28px;
                    line-height: 30px;
                }
                p {
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0;
                    width: 70%;
                }
                .btn {
                    &.btn-secondary{
                        display: none !important;
                    }
                    &.btn.btn-down {
                        margin-top: 0;
                        margin-bottom: 220px;
                    }
                    
                }
            }
        }
    }
}