// Page404

.Page404{
    text-align: center;
    padding: 120px 0 80px;
    background: var(--whiteColor);
    h3{
        color: #2CBE48;
        font-size: 50px;
        font-style: normal;
        font-weight: 800;
        line-height: 112.3%;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
    h4 {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #B5B5B5;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 42px;
        color: transparent;
        line-height: 40px;
    }
    p{
        color: #272727;
        text-align: center;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1.14px;
    }
}